import $ from 'jquery';
window.jQuery = $;
window.$ = $;

//Components
import '../scss/theme.scss';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/modal';
// import './sticky-header.js';

//Images
import '../image/riblab-logo-flat.png';
import '../image/heat-mild.png';
import '../image/heat-med.png';
import '../image/heat-hot.png';



jQuery(document).ready(function (jQuery) {
	//SVG INJECTOR
	var elementsToInject = document.querySelectorAll('.inject-me');
	var injectorOptions = {
		evalScripts: 'once'
	};
	var injector = new SVGInjector(injectorOptions);
	if (jQuery(elementsToInject).length) {
		injector.inject(
			elementsToInject
		);
	}

	//Tracking Pixel
	const trackImage = '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=u4byupe&ct=0:zm2m5i2&fmt=3"/>';

	jQuery('#lafayette a').on('click', function() {
		jQuery('#lafayette').html(trackImage); 
		console.log('tracker fired');
	});
});

//Exists Function
jQuery.fn.exists = function (callback) {
	var args = [].slice.call(arguments, 1);

	if (this.length) {
		callback.call(this, args);
	}

	return this;
};


